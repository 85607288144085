import React, { useState, useRef, useContext, useEffect } from 'react';
import { View, FlatList, TouchableOpacity, Text, StyleSheet, TextInput, RefreshControl, ActivityIndicator, Button, Dimensions, Alert, Window, Platform } from 'react-native';
// import AppCanvas from '../config/AppCanvas';
// import Buttons from "../components/Buttons";
import FormattedDate from '../components/FormattedDate';
import MoneyFormat from '../components/MoneyFormat';
import * as Print from 'expo-print';
// import { shareAsync } from 'expo-sharing';
// import { Print } from 'expo';
import FOLister from '../components/FOLister';
import colors from '../config/colors';
import { Feather } from '@expo/vector-icons';
import AuthContext from '../auth/context';
import services from '../api/services';
import AddFOForm from './AddFOForm';
// import MapComponent from '../components/MapComponent';
import EditFOForm from './EditFOForm'
import HomeScreen from './HomeScreen';
import { AntDesign } from '@expo/vector-icons';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import PrintableComponent from '../components/PrintableComponent'
import "rsuite/dist/rsuite.min.css";
import { DatePicker } from "rsuite";
const SCREEN_HEIGHT = Dimensions.get('window').height;

export default function Loan({ navigation }) {

    const { user, setUser, selectedCo_name, setSelectedCo_name, selectedCo_Tin, setSelectedCo_Tin } = useContext(AuthContext)

    const [fOrders, setFOrders] = useState('')
    const [refreshing, setRefreshing] = useState(true);

    const [freightOrdersPerTruck, setFreightOrdersPerTruck] = useState('')
    const [gettingTruckHistory, setGettingTruckHistory] = useState(false)
    const [selectedTruck, setSelectedTruck] = useState(false)

    const UserID = user.userId
    const coTin = user.userCompTin
    const compInUse = user.userCompName

    useEffect(() => {
        const timeoutId = setInterval(() => { }, 2000);
        getFO()
        getLoan()
        const currentDate = new Date();
        const oneMonthFromNow = new Date();
        oneMonthFromNow.setMonth(currentDate.getMonth() + 1);
        setAppDate(oneMonthFromNow);
        return () => clearInterval(timeoutId);
    }, []);

    const getFO = async () => {
        try {
            const response = await services.getFOForView(coTin);
            if (response.status === 200) {
                setFOrders(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const searchInputRef = useRef('');

    const [isFormVisible, setFormVisible] = useState(false);
    const [isEditFormVisible, setEditFormVisible] = useState(false);

    const handleAddFOForm = () => {
        setFormVisible(true);
    };
    const handleEditForm = () => {
        setEditFormVisible(true)
    }
    const handleCloseForm = () => {
        setFormVisible(false);
        setFO('');
        setTruck('');
        setgrossWeight('');
        setOrderDeliveryDate(null);
        setAppDate(null);
        setOrigin('Djibouti');
        setDestination('Addis Abeba');
        setTariff('');
        setConsignee('');
        setOrderGenerator('Company')
        setRemark('');
    }

    const renderHeader = () => (
        <View style={Styles.tableRow}>
            <Text style={Styles.tableCellHeader}>From</Text>
            <Text style={Styles.tableCellHeader}>To</Text>
            <Text style={Styles.tableCellHeader}>Owner</Text>
            <Text style={Styles.tableCellHeader}>Freight No.</Text>
            <Text style={Styles.tableDateCellHeader}>Departure</Text>
            <Text style={Styles.tableDateCellHeader}>Arrival</Text>
            {/* <Text style={Styles.tableCellHeader}>Truck</Text> */}
            {/* <Text style={Styles.tableCellHeader}>Trailer</Text> */}
            <Text style={Styles.tableCellHeader}>Tariff</Text>
            {/* <Text style={Styles.tableCellHeader}>Weight</Text> */}
            {/* <Text style={Styles.tableCellHeader}>Penality</Text>
            <Text style={Styles.tableCellHeader}>Reason</Text> */}
            <Text style={Styles.tableCellHeader}>Status</Text>
        </View>
    );
    const [selectedScreen, setSelectedScreen] = useState(null);

    const renderScreenContent = () => {
        switch (selectedScreen) {
            case 'truckTable':
                return renderTruckTable();
            case 'Appointment':
                return renderAppointment();
            case 'Loan':
                return renderLoan();
            case 'Penality':
                return renderPenality();
            case 'Income':
                return renderIncome();
            case 'Invoice':
                return renderInvoive();
            case 'Expense':
                return renderExpense();
            default:
                return renderAppointment();
            // return <HomeScreen />;
        }
    };
    const renderTruckTable = () => (
        <View style={Styles.truckDetailContainer}>
            <View style={Styles.truckDetailHeaderContainer}> {/* title container */}
                <Text style={Styles.modalTitle}>{selectedTruck ? <View style={Styles.loadedModalTitle}>Deployment History For Truck {selectedTruck}</View> : <>Deployment History</>}</Text>
                {/* <View style={Styles.twoColContainer}>
                            <View style={Styles.truckDetailLeft}>

                            </View>
                            <View style={Styles.truckDetailRight}>

                            </View>
                        </View> */}
            </View>
            <View style={Styles.tableHeader}>
                {renderHeader()}
            </View>
            <FlatList
                data={freightOrdersPerTruck}
                keyExtractor={(item) => item.idfreightOrder}
                renderItem={renderItem}
            />
        </View>
    );
    const renderExpense = () => (UserID == 1 ? <View><Text>Expense</Text></View> : <View><Text>Unauthorized</Text></View>)
    const renderInvoive = () => (<View><Text>Invoice</Text></View>)
    const renderIncome = () => (<View><Text>Income</Text></View>)
    const renderPenality = () => (<View><Text>Penality</Text></View>)
    const renderLoan = () => (<View><Text>Loan</Text></View>)

    // const printableRef = useRef();



    //---------------------------------------APPOINTMENTS---------------------------------------------

    const [appDate, setAppDate] = useState(null);
    const foInputRef = useRef('');
    const amountInputRef = useRef('');
    const voucherInputRef = useRef('');
    const reasonInputRef = useRef('');
    const AppoSearchInputRef = useRef('');
    const ExpenseSearchInputRef = useRef('');
    const [appointments, setAppointments] = useState('')
    const [deletingApp, setdeletingApp] = useState(false)
    const [postedLoan, setPostedLoan] = useState('')


    const getLoan = async () => {
        try {
            const response = await services.getLoanPosted(coTin);
            if (response.status === 200) {
                setPostedLoan(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const listLoans = ({ item, index }) => {

        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (<>
            {/* <PrintableComponent ref={printableRef} item={item} /> */}
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <Text style={Styles.tableCell}>{item.op_voucher ? item.op_voucher : null}</Text>
                <Text style={Styles.tableCell}>{item.truck_plate ? item.truck_plate : null}</Text>
                <Text style={Styles.tableCell}>{item.idfreightOrder ? item.idfreightOrder : null}</Text>
                {/* <Text style={Styles.tableCell}>{item.origin == 'Addis Ababa' ? 'A.A' : item.origin}</Text>
                <Text style={Styles.tableCell}>{item.destination == 'Addis Ababa' ? 'A.A' : item.destination}</Text> */}
                {/* <Text style={Styles.tableCell}>{item.cus_name}</Text> */}
                {/* <Text style={Styles.tableDateCell}>{item.orderDeparture ? <FormattedDate dateString={item.orderDeparture} /> : null}</Text>
                <Text style={Styles.tableDateCell}>{item.orderUnloaingComplete ? <FormattedDate dateString={item.orderUnloaingComplete} /> : null}</Text> */}
                <Text style={Styles.tableCell}>{item.outgoing_payment_made ? <MoneyFormat amount={item.outgoing_payment_made} /> : null}</Text>
                <Text style={Styles.tableDateCell}>{item.op_added_date ? <FormattedDate dateString={item.op_added_date} /> : null}</Text>
                {/* <Text style={Styles.tableCell}>{item.orderStatus}</Text> */}
                <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="delete" size={14} color="red" onPress={() => deletePayment(item)} />}</View>
                {/* <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="printer" size={14} color={colors.primary} onPress={() => printToFile(item)} />}</View> */}
            </View>
        </>)
    };
    const listAppointments = ({ item, index }) => {

        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (<>
            {/* <PrintableComponent ref={printableRef} item={item} /> */}
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <Text style={Styles.tableCell}>{item.truck_plate}</Text>
                <Text style={Styles.tableCell}>{item.idfreightOrder}</Text>
                <Text style={Styles.tableCell}>{item.cus_name}</Text>
                <Text style={Styles.tableDateCell}>{item.orderDeparture ? <FormattedDate dateString={item.orderDeparture} /> : null}</Text>
                <Text style={Styles.tableDateCell}>{item.orderUnloaingComplete ? <FormattedDate dateString={item.orderUnloaingComplete} /> : null}</Text>
                <Text style={Styles.tableDateCell}>{item.orderPayAppointment ? <FormattedDate dateString={item.orderPayAppointment} /> : null}</Text>
                <Text style={Styles.tableCell}>{item.orderStatus}</Text>
                {/* <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="delete" size={14} color="red" onPress={() => deleteAppointment(item.idfreightOrder)} />}</View> */}
                <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="printer" size={14} color={colors.primary} onPress={() => printToFile(item)} />}</View>
            </View>
        </>)
    };
    const clearForm = () => {
        foInputRef.current.clear()
        amountInputRef.current.clear()
        voucherInputRef.current.clear()
        reasonInputRef.current.clear()
        getLoan()
    }
    const submitLoan = async () => {
        try {
            setaddingLoan(true)
            const response = await services.postLoan(fo, amount, voucher, reason, coTin, UserID);
            if (response.status === 200) {
                clearForm()
                setaddingLoan(false);
                getLoan()
                // getAppointmentsForExpense()
                // getFO()
                window.alert("Loan Posted.")
            } else {
                setaddingLoan(false);
                window.alert("Error posting Loan. Please try again.")
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setaddingLoan(false);
            clearForm()
        }
    };

    const deletePayment = async (item) => {
        try {
            const confirmed = window.confirm(`Are you sure you want to delete voucher: ${item.op_voucher} loan?`);
            if (!confirmed) {
                return;
            }
            setdeletingApp(true);
            const response = await services.deleteLoan(item.idfreightOrder,item.id_op, coTin, UserID);
            if (response.status === 200) {
                getLoan();
                window.alert("Loan deleted.");
                setdeletingApp(false);
            } else {
                setdeletingApp(false);
                window.alert("Error deleting payment. Please try again.");
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setdeletingApp(false);
        }
    };

    const printToFile = async (item) => {
        const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Payment Appointment</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 10px;
            }
            #header {
              text-align: center;
              padding: 10px;
              background-color: #f0f0f0;
            }
            #headerText {
              font-size: 18px;
              font-weight: bold;
            }
            #appointmentDetails {
              margin-top: 20px;
              border: 1px solid #ddd;
              padding: 10px;
            }
            #signature {
              margin-top: 20px;
            }
            #date {
              margin-top: 10px;
            }
          </style>
        </head>
        <body>
          <div id="header">
            <p id="headerText">Unity Transport and Logistics SC</p>
          </div>
          <div id="appointmentDetails">
            <p>Truck: ${item.truck_plate}</p>
            <p>Freight ID: ${item.idfreightOrder}</p>
            <p>From: ${item.origin}</p>
            <p>To: ${item.destination}</p>
            <!-- <p>Payment Amount: - </p> -->
             <p>Payment Date: ${item.orderPayAppointment}</p>
            <!-- Add other appointment details as needed -->
          </div>
          <div id="signature">
            <p>Prepared By: ${UserID}</p>
            <p>Signature: ____________________________</p>
          </div>
          <div id="date">
            <p>Date: ${new Date().toLocaleDateString()}</p>
          </div>
        </body>
        </html>
      `;


        // Use WebView to open a new window with the printable content
        if (Platform.OS === 'web') {
            // Create an iframe to load the HTML content
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);

            // Set the HTML content in the iframe
            iframe.contentDocument.open();
            iframe.contentDocument.write(htmlContent);
            iframe.contentDocument.close();

            // Callback function to notify the main window when the print dialog is closed
            const onPrintDialogClose = () => {
                document.body.removeChild(iframe);
                window.removeEventListener('afterprint', onPrintDialogClose);
            };

            // Listen for the 'afterprint' event, which is triggered when the print dialog is closed
            window.addEventListener('afterprint', onPrintDialogClose);

            // Trigger the print dialog
            iframe.contentWindow.print();
        } else {
            // For mobile platforms, you can use Expo Print API
            // Note: This might not work as expected on the web
            await WebView.printAsync({ html: htmlContent });
        }
    };
    //---------------------------------------APPOINTMENTS END---------------------------------------------

    const [fo, setFO] = useState('')
    const [amount, setAmount] = useState('')
    const [voucher, setVoucher] = useState('')
    const [reason, setReason] = useState('Loan')

    const [addingLoan, setaddingLoan] = useState(false)

    const searchLoan = async (input) => {
        try {
            if (!input || input.trim() === '') {
                return;
            }
            const response = await services.searchLoanPosted(coTin, input);
            if (response.status === 200) {
                setPostedLoan(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };
    return (
        <View style={Styles.truckDetailContainer}>
            <View style={Styles.truckDetailHeaderContainer}> {/* title container */}
                <View style={Styles.modalTitleContainer}><Text style={Styles.modalTitle}>New Advance</Text></View>
                <View style={Styles.formContainer}>
                    <TextInput
                        ref={foInputRef}
                        style={Styles.input}
                        placeholder="Freight order No."
                        onChangeText={setFO}
                    />
                    <TextInput
                        ref={amountInputRef}
                        style={Styles.input}
                        placeholder="Advance Amount"
                        onChangeText={setAmount}
                    />
                    <TextInput
                        ref={voucherInputRef}
                        style={Styles.input}
                        placeholder="Voucher No."
                        onChangeText={setVoucher}
                    />
                    {/* <TextInput
                        ref={reasonInputRef}
                        style={Styles.input}
                        placeholder="Reason"
                        onChangeText={setReason}
                    /> */}
                    <TouchableOpacity style={Styles.buttonContainer} onPress={clearForm}>
                        <Text style={Styles.buttonText}>Clear</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={Styles.buttonContainer} onPress={submitLoan}>
                        {addingLoan ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Submit</Text>}
                    </TouchableOpacity>
                </View>
                {/* <View style={Styles.twoColContainer}>
                            <View style={Styles.truckDetailLeft}>

                            </View>
                            <View style={Styles.truckDetailRight}>

                            </View>
                        </View> */}
            </View>
            {/* <View style={Styles.modalTitleContainer}><Text style={Styles.tableTitle}>  Payment Appointments</Text></View>
            <View style={Styles.searchContainer}>
                <TextInput
                    ref={AppoSearchInputRef}
                    style={Styles.inputTableSearch}
                    placeholder="Search Appointment"
                    onChangeText={(input) => searchAppointment(input)}
                />
                <TouchableOpacity style={Styles.smallButtonContainer} onPress={() => { getAppointmentsForExpense, AppoSearchInputRef.current.clear }}>
                    <Text style={Styles.buttonText}>Clear</Text>
                </TouchableOpacity>
            </View>
            <View style={Styles.tableHeader}>
                <View style={Styles.tableRow}>
                    <Text style={Styles.tableCellHeader}>Truck</Text>
                    <Text style={Styles.tableCellHeader}>Freight</Text>
                    <Text style={Styles.tableCellHeader}>Owner</Text>
                    <Text style={Styles.tableDateCellHeader}>Departure</Text>
                    <Text style={Styles.tableDateCellHeader}>Arrival</Text>
                    <Text style={Styles.tableDateCellHeader}>Pay Appointment</Text>
                    <Text style={Styles.tableCellHeader}>Status</Text>
                    <Text style={Styles.tableCellHeaderSmall}>Delete</Text>
                    <Text style={Styles.tableCellHeaderSmall}>Print</Text>
                </View>
            </View>
            <FlatList
                data={appointments}
                keyExtractor={(item) => item.id_op}
                renderItem={listAppointments}
            /> */}
            <View style={Styles.modalTitleContainer}><Text style={Styles.tableTitle}> Advance Per Freight Order</Text></View>
            <View style={Styles.searchContainer}>
                <TextInput
                    ref={ExpenseSearchInputRef}
                    style={Styles.inputTableSearch}
                    placeholder="Search Loan"
                    onChangeText={(input) => searchLoan(input)}
                />
                <TouchableOpacity style={Styles.smallButtonContainer} onPress={() => { getLoan(), ExpenseSearchInputRef.current.clear }}>
                    <Text style={Styles.buttonText}>Clear</Text>
                </TouchableOpacity>
            </View>
            <View style={Styles.tableHeader}>
                <View style={Styles.tableRowHeader}>
                    <Text style={Styles.tableCellHeader}>Voucher</Text>
                    <Text style={Styles.tableCellHeader}>Truck</Text>
                    <Text style={Styles.tableCellHeader}>Freight</Text>
                    {/* <Text style={Styles.tableCellHeader}>From</Text>
                    <Text style={Styles.tableCellHeader}>To</Text> */}
                    {/* <Text style={Styles.tableCellHeader}>Owner</Text> */}
                    {/* <Text style={Styles.tableDateCellHeader}>Departure</Text>
                    <Text style={Styles.tableDateCellHeader}>Arrival</Text> */}
                    <Text style={Styles.tableCellHeader}>Amount</Text>
                    <Text style={Styles.tableDateCellHeader}>Loan Added</Text>
                    {/* <Text style={Styles.tableCellHeader}>Status</Text> */}
                    <Text style={Styles.tableCellHeaderSmall}>Delete</Text>
                    {/* <Text style={Styles.tableCellHeaderSmall}>Print</Text> */}
                </View>
            </View>
            <FlatList
                data={postedLoan}
                keyExtractor={(item) => item.id_op}
                renderItem={listLoans}
            />
        </View>
    );
}

const Styles = StyleSheet.create({
    smallMenuContainer: {
        elevation: 8,
        backgroundColor: '#031D22',
        height: '10%',
        width: '100%',
        borderRadius: 5,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    smallMenuButton: {
        // backgroundColor: 'green',
        paddingHorizontal: 9,
        paddingVertical: 6,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    selectedsmallMenuButton: {
        // backgroundColor: 'grey',
        backgroundColor: '#09414c',
        paddingHorizontal: 9,
        paddingVertical: 6,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    smallMenuText: {
        fontSize: 16,
        fontWeight: 600,
        color: colors.offwhite,
    },
    truckDetailContainer: {
        height: '86%',
        width: '100%',
        margin: 2,
        elevation: 8,
        borderRadius: 5,
        backgroundColor: colors.AppCanvasMain,
    },
    truckDetailHeaderContainer: {
        // marginTop: 4,
        height: '12%',
        width: '99.7%',
        flexDirection: 'column',
        // alignItems: 'flex-start',
        backgroundColor: colors.AppCanvasMain,
        elevation: 9,
        borderRadius: 5,
        justifyContent: 'center',
        paddingLeft: 5,
        // marginBottom: 5,
        paddingTop: 5,
        borderColor: colors.skyblue,
        borderWidth: 1
    },
    modalTitleContainer: {
        // backgroundColor: 'orange',

    },
    twoColContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    truckDetailLeft: {
        height: '100%',
        width: '50%',
        // backgroundColor: 'ornage'
    },
    truckDetailRight: {
        height: '100%',
        width: '50%',
        backgroundColor: 'green'
    },
    tableHeader: {
        // height: '40%',
        width: '70%',
        // backgroundColor: 'green',
        borderWidth: 1,
        marginLeft: 4,
        borderColor: 'skyblue',
    },
    tableRowHeader: {
        width: '100%',
        flexDirection: 'row',
        borderBottomWidth: .5,
        borderBottomColor: 'skyblue',
        paddingVertical: 5,
        backgroundColor: '#f0f0f0',
    },
    searchContainer: {
        width: '12%',
        flexDirection: "Row",
        height: 30,
        marginBottom: 2
    },
    tableRow: {
        // flex: 1,
        width: '70%',
        flexDirection: 'row',
        borderBottomWidth: .5,
        borderBottomColor: 'skyblue',
        // marginHorizontal: 5,
        paddingVertical: 5,
        backgroundColor: '#f0f0f0', // Header background color,
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    tableCellHeader: {
        flex: .25,
        // padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 3,
    },
    tableCellHeaderSmall: {
        flex: .2,
        // padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableDateCellHeader: {
        flex: .5,
        padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCell: {
        flex: .25,
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 3,
    },
    tableDateCell: {
        flex: .5,
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCellIcon: {
        flex: .2,
        // paddingLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: 5,
    },
    tableTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: 5,
        marginBottom: 2,
    },
    formContainer: {
        flexDirection: 'row',
        //alignItems: 'center'
        marginTop: 5,
        marginBottom: 5,
        paddingBottom: 5,
    },
    input: {
        height: 35,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        width: 160
    },
    inputTableSearch: {
        height: 30,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        marginLeft: 2,
        marginBottom: 2,
        width: 160
    },
    datepickerStyle: {
        // backgroundColor: 'grey',
        //  width: 50
        // height: 30
    },
    buttonContainer: {
        // width: 100,
        marginLeft: 3,
        // alignSelf: 'flex-end',
        // justifyContent: 'space-evenly',
        // flexDirection: 'row',
        marginTop: 2,
        paddingHorizontal: 5,
        backgroundColor: '#2196F3',
        height: 33,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    smallButtonContainer: {
        // width: 100,
        marginLeft: 2,
        // alignSelf: 'flex-end',
        // justifyContent: 'space-evenly',
        // flexDirection: 'row',
        marginTop: 2,
        paddingHorizontal: 3,
        backgroundColor: '#2196F3',
        height: 28,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    buttonText: {
        color: 'white',
        fontWeight: 600,
        paddingHorizontal: 3,
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.AppCanvasMain
    },
    list: {
        width: '100%',
    },
    contactItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'gray',
    },
    contactName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    mainContainer: {
        flex: 1,
        width: '100%',
        // alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: colors.AppCanvasMain,
        height: SCREEN_HEIGHT,
    },
    headerContainer: {
        flexDirection: 'row',
        // alignItems: ''
        justifyContent: 'space-between',
        width: '100%',
        //  backgroundColor: colors.offwhite,
        paddingHorizontal: 12,
        backgroundColor: colors.AppCanvasMain,
        //paddingVertical: 10,
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginRight: 
    },
    headeText: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    searchBarContainer: {
        width: "100%",
        height: 40,
        flexDirection: "row",
        alignContent: "space-between",
        // justifyContent: "center",
        backgroundColor: colors.AppCanvasMain
    },
    FOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        // alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 2,
        backgroundColor: colors.AppCanvasMain,
        // height: SCREEN_HEIGHT,
    },
    Leftview: {
        width: '27%',
        flexDirection: 'column',
        height: (SCREEN_HEIGHT - 5),
    },
    rightView: {
        backgroundColor: colors.AppCanvasMain,
        width: '73%',
        height: SCREEN_HEIGHT,
    },
    NoFOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 5,
        flexDirection: 'column',
        backgroundColor: colors.AppCanvasMain
    },
    AppTextInput: {
        width: "80%",
        height: 40,
        borderRadius: 5,
        borderColor: colors.primary,
        borderWidth: 0.5,
        paddingLeft: 10,
        marginLeft: 5,
        //position: "relative",
        top: 0,
        //alignItems: "center",
        fontSize: 14,
        color: colors.primary,
    },
});