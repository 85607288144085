import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage';

const apiClient = axios.create({
    baseURL: 'https://tms.wedajerp.com',
})

apiClient.interceptors.request.use(
    async config => {
        const token = await AsyncStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
        
    },
    error => {
        Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    response => response,
    async error => {
        if (error.response && error.response.status === 401) {
            await AsyncStorage.removeItem('user');
            await AsyncStorage.removeItem('token');
        } else {
            console.log("Error structure:", error);
        }
        return Promise.reject(error);
    }
);

export default apiClient;