import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Animated } from 'react-native';
import colors from '../config/colors';

const SmallDispoModal = ({ message, visible, onHide }) => {
  const [fadeAnim] = useState(new Animated.Value(0)); // Initial opacity value

  useEffect(() => {
    if (visible) {
      // Fade in animation
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
      }).start(() => {
        // Automatically hide the notification after a delay
        setTimeout(() => {
          // Fade out animation
          Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
          }).start(() => onHide());
        }, 1500); // Display for 1.5 seconds
      });
    }
  }, [visible]);

  if (!visible) return null; // Don’t render if not visible

  return (
    <Animated.View style={[styles.notification, { opacity: fadeAnim }]}>
      <Text style={styles.notificationText}>{message}</Text>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  notification: {
    position: 'absolute',
    bottom: 30,
    right: 20,
    backgroundColor: colors.ForeGroundWhite,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  notificationText: {
    color:colors.primary,
    fontSize: 14,
  },
});

export default SmallDispoModal;
