import React from 'react';
import { Text } from 'react-native';

function FormattedDateSmall({ dateString }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Adjust time zone if necessary
    // date.setHours(date.getHours() + 2); // Uncomment if time adjustment is needed

    const dateOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };

    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // This ensures 12-hour format with AM/PM
    };

    const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
    const timeFormatter = new Intl.DateTimeFormat('en-US', timeOptions);

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    // Check if the year is different from the current year and remove the comma if it's the same
    const currentYear = new Date().getFullYear();
    const dateWithoutYear = formattedDate.replace(/ \d{4}$/, '').replace(/,/, '');

    return date.getFullYear() !== currentYear
      ? `${formattedDate}, ${formattedTime}` // Show full date with time
      : `${dateWithoutYear}, ${formattedTime}`; // Show date without year, with time
  };

  const formattedDate = formatDate(dateString);

  return <Text>{formattedDate}</Text>;
}

export default FormattedDateSmall;
